import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import PageHeader from "../components/page-header";
import Seo from "../components/seo";
import Layout from "../layouts/index";
import { Link } from "gatsby";

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      homeHeader: file(relativePath: { eq: "home-header.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      homeFooter: file(relativePath: { eq: "home-footer.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const src = getImage(data.homeFooter);

  return (
    <Layout>
      <Seo />

      <PageHeader image={data.homeHeader} title="Start a career with us" />

      <div className="block grid-container narrow">
        <p>
          We're one of the largest lenders in the UK used car finance market.
          We’ve specialised in motor finance for over 40 years, and are
          recognised as one of the best car finance providers in the industry.
          We’re part of the Aldermore Group, which includes Aldermore Bank plc.
          Aldermore offers a range of personal and business finance, and savings
          options.
        </p>
        <Link
          to="https://motonovofinance.current-vacancies.com/Careers/MotoNovo_vacancy_search_page-3181"
          rel="noreferrer"
          className="button primary hollow"
        >
          Browse jobs
        </Link>
      </div>

      <GatsbyImage image={src} alt="Motonovo Finance" />
    </Layout>
  );
};

export default Home;
